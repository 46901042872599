@import "./define.sass";
// 导航栏-直通
header {
    position: fixed;
    width: 100%;
    z-index: 99; }
nav.navigation {
    color: $color-typeface; }

.navigation-wrapper {
    position: relative; }


.logo-wrapper {
    position: absolute;
    padding: 3.6rem 4rem;
    left: 0;
    top: 0; }

.mion-logo {
    width: 40px;
    height: 40px; }

.navigation-links-wrapper {}

.corner-mark {
    position: fixed;
    bottom: 0;
    padding: 3.6rem 4.8rem;
    image {
        width: 18px;
        height: 182px; } }
ul.navigation-links {
    li {
        font-weight: 500;
        float: left;
        &:first-child>a {
            padding-left: 0; }
        a {
            display: inline-block;
            padding: 4.2rem 1.6rem;
            color: $color-typeface; } } }


.text-logo-wrapper {
    position: absolute;
    padding: 4rem;
    right: 0;
    top: 0; }

.mion-text-logo {
    display: inline-block;
    clear: both;
    float: right;
    width: 86px;
    height: 24px;
    margin-top: 8 px; }
