// 定义所有颜色
$color-background: #1A191D;
$color-typeface: #C9C6B9;

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-Thin.ttf);
	font-weight: 200;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-Light.ttf);
	font-weight: 300;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-Regular.ttf);
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-Medium.ttf);
	font-weight: 500;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-SemiBold.ttf);
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-Bold.ttf);
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/G-79-Black.ttf);
	font-weight: 800;
	font-style: normal; }

@font-face {
	font-family: 'ST-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/ST-79-Black.otf);
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'ST-69';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/ST-79-Medium.otf);
	font-weight: Medium;
	font-style: Medium; }

@font-face {
	font-family: 'CT-79';
	src: url(https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/fonts/CT-793.ttf);
	font-weight: Bold;
	font-style: Bold; }



// 辅助定位单位

.set-top-s {
    margin-top: 8px; }
.set-top-m {
    margin-top: 16px; }
.set-top-l {
    margin-top: 24px; }
.set-top-xl {
    margin-top: 32px; }
.set-top-xxl {
    margin-top: 40px; }
.set-top-xxxl {
    margin-top: 48px; }
