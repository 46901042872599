@media (max-width: 868px) {
    #m-background-GL {
        display: none; }

    #mobile-video {
        display: initial; }

    .logo-wrapper {
        padding: 3.5rem 13%!important; }

    .text-logo-wrapper {
        padding: 4rem 13%!important; }
    ul.navigation-links {
        display: none; }

    .intro-bottom-container {
        flex-direction: column;

        .bottom-subinfo-1 {
            flex: 1;
            padding-right: 0px;
            padding-bottom: 20px; }
        .bottom-subinfo-2 {
            flex: 1;
            padding-right: 0px;
            padding-bottom: 20px; }
        .bottom-subinfo-3 {
            padding: 0 0;
            flex: 1;
            display: none; }
        .bottom-subinfo-4 {
            flex: 1;
            margin-top: 2em; }

        a.download {
            margin-top: 0;
            width: 100%!important; } }

    .intro-main-wrapper {
        h1 {
            font-size: 3.2em;
            width: 280px;
            line-height: 1.1; }

        h3 {
            font-size: 0.9em; }
        p {
            margin-top: 0.7em;
            font-size: 0.85em; }

        table {
            margin-top: 1.7em; } }

    .intro-main {
        flex: 0.8; }
    .intro-bottom {
        flex: 1;
        padding-bottom: 3rem; }
    .corner-mark {
        display: none; }


    section#second {
        padding: 10vh 0 0 0;
        height: auto;

        .second-wrapper {
            flex-direction: column;
            padding: 9vh 13% 0vh 13%; }
        .paint {
            flex: 1!important;
            margin-right: 0!important;
            align-items: flex-end!important;

            img {
                width: 100%;
                height: auto; } }

        .second-context {
            flex: 2!important;
            margin-top: -8vh;
            h2 {
                text-align: left;
                font-size: 2.2em!important;
                width: 100%; }
            p {
                font-size: 0.96em!important; } } }

    section#third {
        padding: 6vh 0 0 0;
        height: auto;

        .third-wrapper {
            flex-direction: column;
            padding: 9vh 13%; }
        .paint {
            flex: 1!important;
            margin-left: 0!important;
            align-items: flex-end!important;

            img {
                width: 100%;
                height: auto; } }

        .third-context {
            flex: 2!important;
            margin-top: -2vh;
            h2 {
                text-align: left;
                font-size: 2.2em!important;
                width: 100%; }
            p {
                font-size: 0.96em!important; }

            div.icons {

                div.card,div.cast,div.film {}


                div.icon {
                    img {
                        min-width: 10px!important;
                        zoom: 0.65; } } } } }

    section#fourth {
        padding: 8vh 0 0 0;
        height: auto;

        .fourth-wrapper {
            flex-direction: column;
            padding: 0 13% 0 13%; }
        .paint {
            flex: 1!important;
            margin-right: 0!important;
            align-items: flex-end!important;

            img {
                width: 100%;
                height: auto; } }

        .fourth-context {
            flex: 2!important;
            margin-top: 4vh;
            h2 {
                text-align: left;
                font-size: 2.2em!important;
                width: 100%; }
            p {
                font-size: 0.96em!important; } } }

    section#fifth {
        padding: 14vh 0 10vh 0;
        height: auto;

        .fifth-wrapper {
            flex-direction: column;
            padding: 0 13% 0 13%; }
        .paint {
            flex: 1!important;
            margin-left: 0!important;
            align-items: flex-end!important;

            img {
                width: 100%;
                height: auto; } }

        .fifth-context {
            flex: 2!important;
            margin-top: 4vh;
            h2 {
                text-align: left;
                font-size: 2.2em!important;
                width: 100%; }
            p {
                font-size: 0.96em!important; } } }

    section#outro {

        #outro-video {}


        .outro-wrap {}

        .outro-context {
            flex: 1;

            h2 {
                text-align: left;
                font-size: 2.2em!important;
                width: 100%; }
            p {
                font-size: 0.96em!important; } }
        a.download-bottom {
            width: 100%; } }

    footer {
        .footer-wrapper {
            flex-direction: column; }

        &>div {
            ul {
                li {
                    text-align: left; } } }

        div.middle {
            margin-top: 2em; } }

    // chinese hack
    .h1-cn {
        font-size: 12.4vw!important;
        width: 100%!important; }

    .intro-main-cn {
        flex: 1.2!important; } }
