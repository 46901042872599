@import "./define.sass";
// 全局样式的定义
@import "./import/bourbon/bourbon.scss";
@import "./import/normalize.css";
@import "./import/animate.css";
@import "./import/superb-easing.sass";
// 一些样式扩展
$color-table-border: #7B77CF;
// 全局样式
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: style; } //解决苹方伪粗体


body {
    background: $color-background;
    color: $color-typeface;
    text-justify: ideographic;
    font-size: 15px;
    font-family: G-79, PingFang-TC ,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto , Ubuntu, 'Helvetica Neue', sans-serif; }

.cn-typo {
    text-align: justify; }


::selection {
  background: #241E98; }

::-moz-selection {
  background: #241E98; }


@mixin use2x() {
	background-size: 75% 75%; }

a,a:hover,a:active,a:visited,a:link {
	text-decoration: none;
	outline: none; }

.app .m-wrapper {
    padding: 0 24px; }

.m-container {
    padding: 0 13%; }

section.intro {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 0; }

#m-background-GL {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 0; }

#mobile-video {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 0;
    display: none;
    object-fit: cover; }

.background-overlay {
    background-color: rgba(100,90,255,0.74);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2; }



.intro-main-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;

    h1 {
        display: block;
        font-size: 6em;
        font-weight: 500;
        width: 900px;
        letter-spacing: -0.09rem;
        font-family: ST-79;
        text-align: left;
        font-style: normal; }

    .h1-cn {
        font-size: 5.6em;
        font-weight: bold;
        font-style: normal;
        font-family: CT-79;
        line-height: 1.3;
        padding-top: 50px; }




    h3 {
        font-size: 1.18em;
        font-weight: bold;
        letter-spacing: 0.0rem; }

    p {
        display: block;
        margin-top: 24px;
        font-size: 1.06em;
        font-weight: 500;
        line-height: 1.5em;
        font-weight: normal; }

    table {
        border: 3px solid $color-table-border;
        width: 100%;
        height: 64px;
        font-size: 0.9em;
        margin-top: 46px;

        span.th-title {
            font-weight: 600;
            opacity: 0.40; }

        .luxu-logo {
            margin-top: 4px; }

        th {
            border: 3px solid $color-table-border;
            text-align: center;
            vertical-align: middle;
            width: 50%; }
        tr {
            text-align: center;
            vertical-align: middle; } } }
a.download {
    box-shadow: 0 0 0 3px $color-typeface inset;
    border-radius: 8px;
    width: 80%;
    float: right;
    height: 64px;
    font-size: 0.9em;
    margin-top: 46px;
    display: block;
    background-image: url(../images/app-store.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 124px 326px; }

a.download-bottom {
    box-shadow: 0 0 0 3px $color-typeface inset;
    border-radius: 8px;
    width: 60%;
    float: left;
    height: 64px;
    font-size: 0.9em;
    margin-top: 0;
    display: block;
    background-image: url(../images/app-store.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 124px 326px; }




.intro-main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }

.intro-main {
    flex: 1.5;
    display: flex;
    align-items: flex-end;
    z-index: 3; }

.intro-bottom {
    flex: 1;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5rem; }

.intro-bottom-container {
    display: flex;

    .bottom-subinfo-1 {
        flex: 0.7;
        padding-right: 40px; }
    .bottom-subinfo-2 {
        flex: 0.7;
        padding-right: 20px; }
    .bottom-subinfo-3 {
        padding: 0 20px;
        flex: 1; }
    .bottom-subinfo-4 {
        flex: 1; } }



.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px; }


.App-header {
    background-color: #222;
    height: 250px;
    padding: 20px;
    color: white; }


.App-title {
    font-size: 1.5em; }


.App-intro {
    font-size: large; }



section#second {
    width: 100%;
    height: auto;
    padding: 12vh 0 6vh 0;
    position: relative;
    z-index: 0;
    display: flex;

    .second-wrapper {
        display: flex;
        padding: 0 13%;
        align-items: center;
        overflow: hidden;

        .paint {
            flex: 1;
            margin-right: 4em;
            min-width: 320px; //--

            img {
                width: 100%;
                height: 100%; } }

        .second-context {
            flex: 1;
            display: flex;
            flex-direction: column;
            min-width: 0;

            h2 {
                font-family: ST-79;
                font-size: 3.8vw;
                line-height: 1.1;
                display: flex;
                flex: 1.1;
                align-items: flex-end; }



            .h2-cn {
                font-size: 3.2em;
                font-weight: bold;
                font-style: normal;
                font-family: CT-79;
                text-align: left; }

            p {
                padding-top: 2em;
                display: block;
                flex: 1;
                font-size: 1.3em;
                line-height: 1.48; } } } }

section#third {
    width: 100%;
    height: auto;
    padding: 12vh 0 6vh 0;
    position: relative;
    z-index: 0;
    display: flex;

    .third-wrapper {
        display: flex;
        padding: 0% 13%;
        align-items: center;
        flex-direction: row-reverse;
        overflow: hidden;

        .paint {
            flex: 1;
            margin-left: 6em;
            min-width: 320px; //--

            img {
                width: 100%;
                height: 100%; } }
        .third-context {
            flex: 1;
            display: flex;
            flex-direction: column;

            h2 {
                font-family: ST-79;
                font-size: 3.8vw;
                line-height: 1.1;
                display: flex;
                flex: 1.1;
                align-items: flex-end;
                width: 6em; }

            .h2-cn {
                font-size: 3.2em;
                font-weight: bold;
                font-style: normal;
                font-family: CT-79; }

            p {
                padding-top: 2em;
                display: block;
                flex: 1;
                font-size: 1.3em;
                line-height: 1.48; }

            div.icons {
                display: flex;
                width: 100%;
                margin-top: 60px;
                div.card,div.cast,div.film {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center; }

                div.card {
                    flex: 0.3; }

                div.film {
                    flex: 0.6; }


                div.icon {
                    flex: 1;
                    text-align: center;
                    height: 80px;
                    img {
                        display: inline-block;
                        justify-content: center;
                        height: 80px; } }


                div.iconName {
                    flex: 1;
                    text-align: center;
                    margin-top: 10px; } } } } }

section#fourth {
    width: 100%;
    height: auto;
    padding: 12vh 0 6vh 0;
    position: relative;
    z-index: 0;
    display: flex;

    .fourth-wrapper {
        display: flex;
        padding: 0% 13%;
        align-items: center;
        overflow: hidden;

        .paint {
            flex: 1;
            margin-right: 8em;
            min-width: 320px; //--

            img {
                width: 100%;
                height: 100%; } }
        .fourth-context {
            flex: 1;
            display: flex;
            flex-direction: column;

            h2 {
                font-family: ST-79;
                font-size: 3.8vw;
                line-height: 1.1;
                display: flex;
                flex: 1.1;
                align-items: flex-end; }


            .h2-cn {
                font-size: 3.2em;
                font-weight: bold;
                font-style: normal;
                font-family: CT-79; }

            p {
                padding-top: 2em;
                display: block;
                flex: 1;
                font-size: 1.3em;
                line-height: 1.48; } } } }

section#fifth {
    width: 100%;
    height: auto;
    padding: 12vh 0 12vh 0;
    position: relative;
    z-index: 0;
    display: flex;

    .fifth-wrapper {
        display: flex;
        padding: 0% 13%;
        align-items: center;
        flex-direction: row-reverse;
        overflow: hidden;

        .paint {
            flex: 1;
            margin-left: 10em;
            min-width: 320px; //--

            img {
                width: 100%;
                height: 100%; } }
        .fifth-context {
            flex: 1;
            display: flex;
            flex-direction: column;

            h2 {
                font-family: ST-79;
                font-size: 3.8vw;
                line-height: 1.1;
                display: flex;
                flex: 1.1;
                align-items: flex-end; }


            .h2-cn {
                font-size: 3.2em;
                font-weight: bold;
                font-style: normal;
                font-family: CT-79; }

            p {
                padding-top: 2em;
                display: block;
                flex: 1;
                font-size: 1.3em;
                line-height: 1.48; }

            div.icons {
                display: flex;
                width: 100%;
                margin-top: 60px;
                div.card,div.cast,div.film {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center; }

                div.card {
                    flex: 0.3; }

                div.film {
                    flex: 0.6; }


                div.icon {
                    flex: 1;
                    text-align: center;
                    height: 80px;
                    img {
                        display: inline-block;
                        justify-content: center;
                        height: 80px; } }


                div.iconName {
                    flex: 1;
                    text-align: center;
                    margin-top: 10px; } } } } }

section#outro {
    width: 100%;
    height: 80vh;
    background: rgba(100,90,255,1);
    position: relative;
    display: flex;
    flex-direction: row;
    #outro-video {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
        z-index: 0;
        object-fit: cover;
        opacity: 0.4; }


    .outro-wrap {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 74vw;
        height: 100%;
        padding: 0 13%;
        overflow: hidden;
        flex-direction: row-reverse;
        align-items: center; }

    .outro-context {
        z-index: 1;
        flex: 0.3;
        display: flex;
        flex-direction: column;

        h2 {
            font-family: ST-79;
            font-size: 3.8vw;
            line-height: 1.1;
            display: flex;
            flex: 1.1;
            align-items: flex-end;
            width: 10em; }

        .h2-cn {
            font-size: 3.2em;
            font-weight: bold;
            font-style: normal;
            font-family: CT-79; }

        p {
            padding-top: 2em;
            display: block;
            flex: 1;
            font-size: 1.3em;
            line-height: 1.48; } } }

footer {
    .footer-wrapper {
        display: flex;
        padding: 6em 13%;
        align-items: flex-start;
        overflow: hidden;
        font-size: 1.1em; }
    a {
        text-decoration: none;
        color: $color-typeface; }
    &>div {
        flex: 1;

        ul {
            li {
                margin-bottom: 1.2em;
                text-align: right; } } }

    div.left {
        flex: 1;
        padding-right: 10vw;
        img {
            display: block;
            margin-bottom: 20px; }
        p {
            display: block;
            margin-top: 5px;
            line-height: 1.2em;
            text-align: left; } }
    div.middle {
        flex: 1;
        padding-top: 44px; }
    div.right {
        flex: 1;
        padding-top: 44px; }

    .split-line {
        opacity: 0.2; }
    a.mion-support {
        color: #8C6CDE;
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: 0.07rem; } }

p {
    letter-spacing: -0.01rem; }

.p-cn {
    letter-spacing: -.03rem;
    line-height: 1.74!important;
    font-size: 1.1em!important; }



.hide {
    // visibility: hidden!important
 }    // display: none!important

.beian {
    font-size: 0.9em;
    color: #e7e7e7!important; }

// Media Support Phones
@import "./mobile.sass";
